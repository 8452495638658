import React from 'react';

const Game03 = () => {
  return (
    <div className='max-w-7xl mx-auto text-center py-24'>
      <h2>Game03</h2>
    </div>
  );
};

export default Game03;
